@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom fonts */
@font-face {
  font-family: "TT Interfaces";
  src: url("../public/fonts/TTInterfaces-Regular.otf");
}

@font-face {
  font-family: 'Manrope-Regular';
  src: url('../public/fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('../public/fonts/Manrope-SemiBold.ttf');
}
@font-face {
  font-family: 'Manrope-Bold';
  src: url('../public/fonts/Manrope-Bold.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: url('../public/fonts/Manrope-ExtraBold.ttf');
}
@font-face {
  font-family: 'Manrope-ExtraLight';
  src: url('../public/fonts/Manrope-ExtraLight.ttf');
}

@font-face {
  font-family: 'Manrope-Light';
  src: url('../public/fonts/Manrope-Light.ttf');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url('../public/fonts/Manrope-Medium.ttf');
}


@font-face {
  font-family: "TT Interfaces";
  src: url("../public/fonts/TTInterfaces-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "TT Interfaces";
  src: url("../public/fonts/TTInterfaces-DemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "TT Interfaces";
  src: url("../public/fonts/TTInterfaces-Bold.otf");
  font-weight: 700;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

/* here is what you need to add to your code */
html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  overflow-x: hidden;
  font-family:'TT Interfaces', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Manrope-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe{
  height: 800px !important;
}


.markdown-article table{
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: inset 0px 0px 1px #ccc;
}

.markdown-article table thead{
  background: #f5f5f5;
  border-bottom-left-radius: 10px;
}

.markdown-article table tbody td{
  white-space: pre-wrap;
  padding: 5px 15px;
  border: 1px solid #f5f5f5;
  text-align: left;
}

.markdown-article table thead th{
  padding: 15px;
}

.markdown-article h1{
  font-size: 1.875rem;
  line-height: 1.2;
  font-weight: 600;
  white-space: pre-line;
  text-align: left;
}

.markdown-article h2{
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  white-space: pre-line;
  text-align: left;
}

.markdown-article h3{
  font-size: 1.17em;
  line-height: 1.2;
  font-weight: 600;
  white-space: pre-line;
  text-align: left;
}

div[role='tooltip'].react-tooltip__show {
  background: #373a45 !important;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  br:not(.dont_ignore) {
    display: none;
  }
}
