.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.legal p, ul {
  margin: 20px 0;
}

ul.addresses{
  margin:0;
}

ul.addresses li:nth-child(2){
  padding-top:20px;
}

.legal a {
  color: #2563EB;
  text-decoration: underline;
}

.legal br {
  display: unset !important;
}

.legal h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 3rem 0 0;
}

.legal h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 1.5rem 0 0;
}

.legal h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0 0;
}
